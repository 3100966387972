
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* exception handling, rendering issue occurs when ant-modal child changes its size (ex.Table) */
.ant-modal {
  display: flex;
  background: white;
  flex-direction: column;
  padding: 0px;
  border-radius: 8px;
}
.ant-modal-content {
  display: flex;
  background: white;
  flex-direction: column;
  padding: 20px;
}
/*  */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-empty {
  width: 0;
  height: 0;
  overflow: hidden;
}

.ant-empty-img-simple {
  width: 0;
  height: 0;
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-modal-body .ant-divider-horizontal {
  margin: 18px 0;
}

.ant-table-container table th {
  word-break: keep-all;
}

.ant-modal {
  max-height: 80%;
}

.ant-modal-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ant-modal-body {
  overflow-y: auto;
  position: relative;
}

.ant-divider.ant-divider-horizontal {
  min-width: 200px;
  max-width: 1300px;
  margin: 12px 0;
}

#root {
  height: 100%;
}

.react-calendar__month-view__days__day {
  align-items: center;
}

abbr, span.ant-typography {
  word-break: keep-all;
}

span.anticon {
  align-self: center;
}

.react-calendar__tile .ant-badge {
  margin-top: 0.2em;
}

.react-calendar__tile--small .ant-badge {
  margin-left: 50%;
  margin-top: -13px;
}
