.react-calendar {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--small {
  font-size: 11px;
}
.react-calendar--small .react-calendar__navigation__label {
  font-size: 14px;
}

.react-calendar--mobile {
  min-width: 320px;
  flex: 1;
  font-size: large;
}
abbr[title] {
  text-decoration: none;
 }
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: medium;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  background: none;
  padding: 10px 6.6667px;
  text-align: start;
  line-height: 16px;
  display: flex;
  height: 47px;
  flex-direction: column;
  align-items: center;
}

.react-calendar__tile--small {
  max-width: 100%;
  background: none;
  padding: 5px 3px;
  line-height: 10px;
  height: 24px;
  text-align: start;
  display: flex;
  flex-direction: column;
}
.react-calendar__tile:enabled:hover {
  background-color: #4e4eb3;
  border-radius: 5px;
  color: white;
}

.react-calendar__tile--now {
  display: flex;
  text-align: start;
  background-color: #acacc9;
  border-radius: 5px;
  color: #4e4eb3;
}

.react-calendar__tile--active {
  border-radius: 5px;
  background-color: #4e4eb3;
  color: white;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}